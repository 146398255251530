import React from 'react'
import { A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';

export default function Testimonials() {


  return (
    <>
        <section className="container py-5 my-2 my-md-4 my-lg-5">
            <div className="row pt-2 py-xl-3">
                <div className="col-lg-3 col-md-4">
                    <h2 className="h1 text-center text-md-start mx-auto mx-md-0 pt-md-2" style={{maxWidth: '300px'}}>What <br className="d-none d-md-inline" />People Say 💚</h2>
                    {/* Slider controls (Prev / next buttons) */}
                    {/* <div className="d-flex justify-content-center justify-content-md-start pb-4 mb-2 pt-2 pt-md-4 mt-md-5">
                        <button type="button" id="prev-testimonial" className="btn btn-prev btn-icon btn-sm me-2 review-swiper-button-prev" tabIndex={0} aria-label="Previous slide" aria-controls="swiper-wrapper-5a3454f1bcbb1f8c">
                            <i className="bx bx-chevron-left" />
                        </button>
                        <button type="button" id="next-testimonial" className="btn btn-next btn-icon btn-sm ms-2 " tabIndex={0} aria-label="Next slide" aria-controls="swiper-wrapper-5a3454f1bcbb1f8c">
                            <i className="bx bx-chevron-right" />
                        </button>
                    </div> */}
                </div>
                <div className="col-lg-9 col-md-8">
                    <Swiper
                        // install Swiper modules
                        modules={[ Autoplay, A11y ]}
                        spaceBetween={50}
                        slidesPerView={2}
                        breakpoints={{
                            200: {
                                spaceBetween: 50,
                                slidesPerView: 1
                            },
        
                            600: {
                                spaceBetween: 50,
                                slidesPerView: 2
                            },

                            700: {
                                spaceBetween: 50,
                                slidesPerView: 2
                            },

                            800: {
                                spaceBetween: 50,
                                slidesPerView: 2
                            },

                            900: {
                                spaceBetween: 50,
                                slidesPerView: 2
                            },
        
                            1000: {
                                spaceBetween: 50,
                                slidesPerView: 2.1
                            },
                        }}
                        autoplay={{ delay: 6000 }}
                    >
                        <div className="swiper mx-n2">
                    
                            <div className="swiper-wrapper" aria-live="polite" style={{transform: 'translate3d(-847px, 0px, 0px)', transitionDuration: '0ms'}}>
                                
                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">Being on the plan was super useful in helping stay on track with my weight loss goals. The  team was also willing to help switch our meals on request.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Shrimp avocado salad, couscous...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Tolulope Ojo</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">I’d never liked the idea of couscous and bulgur as a meal. Because they are uninteresting to eat, but the way you prepare it changed my mind . Your meals were fresh and delivered on time.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Pasta with meat sauce, jollof bulgur...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Oluwatobi Asekun</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">Greet improvement, I lost 6kg in 5days I always look forward to the next meal it’s fun and interesting. It saves me the stress of thinking of what to eat the next day.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Vegetable brown rice, jollof bulgur...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Precious Chizoba Linus</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">First off it’s the customer service!!! It’s something we rarely get here in these parts and Easy fit does it seamlessly, the expectional service cascades down to the riders.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 White rice and sautéed vegetables...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Nkechi</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">It was a great experience, I wish I had been more disciplined. I lost about 3kg and my bloating disappeared. Most of the food was delicious, I will definitely be returning.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Jollof bulgur, mashed potatoes...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Philippa</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">Easyfit is a very helpful and affordable way to eat healthier meals without the stress of thinking and cooking, meals are tasty, nicely portioned and packaged with tamper proof seal.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Fried rice, grilled sweet potatoes...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Sola</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0"> I have thoroughly enjoyed this journey with Easyfit. I don’t even look at the menu as I love the excitement of being surprised by the day’s meal. </p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Mixed berries smoothie, crunchy...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Ehis</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">To be honest, you guys are really good and I don’t think you get enough credit. You take feedback and your customer service is top notch and I’m at ease with your brand.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Peanut butter toast, egg and sand...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Harmony</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0">Your customer service is great, your team is so well organized. You have been so inclusive especially for a very busy individual like myself. Keep the great work going...it’s great.</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                <blockquote>
                                                    <p>Top meals:🥘 Smoothie, chia seed pudding...</p>
                                                </blockquote>
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Salami</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-slide h-auto pt-4 swiper-slide-duplicate swiper-slide-prev" role="group" aria-label="4 / 4" style={{width: '415.5px', marginRight: '8px'}} data-swiper-slide-index={3}>
                                        <figure className="d-flex flex-column h-100 px-2 px-sm-0 mb-0 mx-2">
                                            <div className="card h-100 position-relative border-0 shadow-sm pt-4">
                                            <span className="btn btn-icon btn-primary shadow-primary pe-none position-absolute top-0 start-0 translate-middle-y ms-4">
                                                <i className="bx bxs-quote-left" />
                                            </span>
                                            <blockquote className="card-body pb-3 mb-0">
                                                <p className="mb-0"> I like that the meals are delivered early and almost at the same time everyday like clock wise. I also like portions and how the calories  of all the meals are clearly stated. Love the tamper seal, the meals are also tasty and well cooked. Well done Easy fit!!!</p>
                                            </blockquote>
                                            <div className="card-footer border-0 text-nowrap pt-0">
                                                {/* <blockquote>
                                                    <p>Top meals:🥘 Smoothie, chia seed pudding...</p>
                                                </blockquote> */}
                                            </div>
                                            </div>
                                            <figcaption className="d-flex align-items-center ps-4 pt-4">
                                            <img src="./avatar.png" width={48} className="rounded-circle" alt="Albert Flores" />
                                            <div className="ps-3">
                                                <h6 className="fs-sm fw-semibold mb-0">Adejiire</h6>
                                            </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </SwiperSlide>
                            
                            </div>
                        
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                        </div>
                    </Swiper>
                </div>
            </div>
        </section>
    </>
  )
}
