import React from 'react'
import Footer from '../components/Footer'
import Navbarlight from '../components/Navbarlight'

export default function Terms() {
  return (
    <>
        <Navbarlight />

        <div className='container-fluid p-0 pt-3 mt-5'>
            <div className="ignore-dark-mode overflow-hidden">
                <div className="row align-items-center g-0">
                    <div className="col-12 text-center">
                        <div className="py-5">
                            <h1 className="display-4 mb-0 pt-3 pt-md-0">Terms & Conditions</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <section className="container">
            <div className="row pt-5 mt-n2 mt-sm-0 mt-md-2 mt-lg-4 mt-xl-5 mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>Introduction</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">These Terms & Conditions apply to the Easyfit application including, without limitation, Easyfit's iOS app, Android app or web app, and the Easyfit website located at www.easyfitmeals.ng (collectively, the "App"). As used in these Terms & Conditions, “Easyfit", "us", or "we” refers to Easy Health & Fitness Solutions and its subsidiaries and affiliates.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>1. General</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">YOUR USE OF THE APP IS GOVERNED BY THESE TERMS & CONDITIONS BY ACCESSING OR OTHERWISE USING THE APP, YOU AGREE TO THESE TERMS & CONDITIONS IN THEIR ENTIRETY. Any person or entity who interacts with the App through the use of crawlers, robots, browsers, data mining or extraction tools, or other functionality, whether such functionality is installed or placed by such person or entity or a third party, is deemed to be using the App and bound by these Terms & Conditions.</p>
                    <p className='fs-lg'>We may revise and update these Terms & Conditions from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the App thereafter. Your continued use of the App following the posting of revised Terms & Conditions means that you accept and agree to the changes. You are expected to check this page each time you use the App so you are aware of any changes, as they are binding on you. If at any time you do not accept all of these Terms & Conditions, you must immediately stop using all or any part of the App.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>2. Subscriptions</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">Easyfit allows customers to make purchases by using either: (1) Meal plan subscriptions; or (2) Single order meal purchases. The following terms regarding ordering and shipment are applicable to Order Methods as specified:</p>
                    <p className="fs-lg"><b>Shipping & Handling:</b> All Order Methods<br/>
                        You agree to pay any shipping and handling charges shown at the time you make a purchase. We reserve the right to increase, decrease, add or eliminate shipping and handling charges from time to time, but we will provide notice of the charges applicable to you before you make your purchase. Generally, shipping is handled by a third party courier. When you purchase meals from our App, any shipping times shown are estimates only. Actual delivery time may vary. You agree that you will not obtain meals for resale.
                        Upon delivery at the address specified in the applicable order, ownership and the risk of loss with respect to the meals pass to you. Following delivery, you, and not EasyFit, are solely responsible for the proper and safe storage of the meals. By ordering any of our meals, you agree to use and consume our meals at your own risk.</p>
                    <p className="fs-lg">If you are not at home when your shipment arrives, in certain geographical areas, the courier will generally leave the package for you at your door or with your security unless other delivery instructions have been communicated to us.  You should plan for immediate and proper storage of your meals after delivery, refrigerate if you’re not consuming immediately. If something is missing from your delivery or you have any concerns about your package, please contact us at <a href='mailto:easyfitng@gmail.com'>easyfitng@gmail.com</a>.</p>
                    <p className="fs-lg"><b>Deliveries and Food Safety:</b> All Order Methods<br/>
                        You are responsible for inspecting all meals for any damage or other issues upon delivery. In the unlikely event that you have any reason to believe that any item in your delivery or purchase is not suitable for consumption, contact us at <a href='mailto:easyfitng@gmail.com'>easyfitng@gmail.com</a> before you discard the item. To maintain the quality and integrity of our meals, we recommend that you immediately refrigerate upon receipt if you’re not consuming immediately.</p>
                    <p className="fs-lg"><b>Weight Loss and Health Claims:</b> All Order Methods<br/>
                        EasyFit provides information regarding its products for informational purposes only. Any weight loss and health results mentioned on the App, whether by EasyFit or others, are not guaranteed. Your results may vary considerably from those described through the App. You expressly acknowledge that Easyfit does not make any express or implied claims or guarantees that you will achieve or maintain any specific results through your use of EasyFit's products and services. EasyFit does not refund any payments or agree to cancel your participation in a subscription plan based on your failure to achieve your desired results.</p>
                    <p className="fs-lg">EasyFit meals and subscription plans are not medically-supervised. You should seek the advice of a physician or dietary consultant before starting any dietary program to ensure it is suitable for your specific dietary needs. EasyFit does not  guarantee the accuracy, completeness, or usefulness of any nutritional information of any meal or subscription plan or adopt, endorse, or accept responsibility for the accuracy, completeness or usefulness of any nutritional information regarding any meal or subscription plan.</p>
                    <p className="fs-lg"><b>Delivery Policy</b><br/>
                        We will attempt to deliver your order as soon as possible. We reserve the right not to deliver, or to delay delivery (as applicable): outside our designated delivery times; outside our delivery zone; in inclement weather or dangerous driving conditions; to minors; to customers in public establishments; or in those instances in which we believe our delivery would conflict with, compromise or affect our business. You will be charged for your order even if we are unable to deliver your order because you provided an inaccurate address or  other inaccurate delivery information. If you request that your order be left at your door or another specified location, rather than delivered to you personally, you will be responsible for any and all loss, spoilage, theft, infestation or damage to the product occurring after the product has been delivered, and any such loss, spoilage, theft, infestation, or damage shall not excuse you from your obligation to pay for such product. If you request that your order be delivered to you personally and we are unable to locate you at the address provided, we will attempt to contact you by phone or SMS. You will be charged for your order even if you are not at the designated location at the time of delivery, or we are unable to contact you. Delivery time frames listed on the App are estimates and do not guarantee delivery within the time listed.</p>
                    <p className="fs-lg"><b>Third Party Delivery Service:</b> All Order Methods<br/>
                        You acknowledge that we use third party delivery services to deliver your order. When you place your order with us, we will provide your name, address, contact information, including phone number, and information regarding your order to the Delivery Service so that they may deliver your order to you. In addition, we may provide other information to the Delivery Service, such as information regarding comments, reviews or ratings we receive from you regarding the Delivery Service to help improve the quality of their service. You agree that we may provide the foregoing information to the applicable Delivery Service.</p>
                    <p className="fs-lg"><b>Editing, Skipping or Cancelling Orders</b><br/>
                        Orders may be modified, skipped, or cancelled any time before 5;00pm a day before your delivery is due. Any modifications, skips, or cancellations requested after will be deemed to be rejected by EasyFit.</p>
                    <p className="fs-lg"><b>Prices and Payment</b><br/>
                        All prices shown on the App are subject to change at any time without notice. The price available through the App reflects the price available at the current time and supersedes any and all prior prices listed or communicated for any product or plan. We may offer promotional pricing, frequency discounts, incentive pricing, loyalty discounts and other alternative pricing structures for some or all of our subscription plans to some or all of our customers from time to time at our discretion. We reserve the right to discontinue, modify or extend such pricing at our discretion without prior notice to you.</p>
                    <p className="fs-lg">When you register for a subscription plan, you agree that Easyfit or its third party payment processor is authorized to charge you for your subscription plan, delivery fees, or other applicable charges, for as long as your subscription plan continues.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>3. Proprietary rights</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">The App and all of its features, functionality, and content, such as recipes, meal planners, guides, shopping lists, sales flyers, text, graphics, logos, images, button icons, audio clips, video, photographs, data, music, software, and other material (collectively "Content") are owned by EastFit, its licensors, or other providers of such material. These Terms & Conditions permit you to use the App for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App, except as follows: (1) your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials; (2) you may store files that are automatically cached by your web browser for display enhancement purposes; and (3) you may print or download proof of payment or information regarding your orders, for your personal purposes. Accessing, downloading, printing, posting, storing, or otherwise using the App or any of the Content for any commercial purpose, whether on behalf of yourself or on behalf of any third party, constitutes a material breach of these Terms & Conditions. Nothing in these Terms & Conditions or otherwise will be deemed to grant to you an ownership interest in the App or the Content, in whole or in part.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>4. Trademarks</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">EasyFit logos and other related marks, design marks, product names, feature names and related logos used in the App are trademarks of EasyFit and may not be used, copied, or imitated, in whole or in part, without the express prior written permission of EasyFit. In addition, the look and feel of the App constitutes the service mark, trademark and/or trade dress of EasyFit and may not be copied imitated or used, in whole or in part, without the express prior written permission of EasyFit. EasyFit’s trademarks and trade dress may not be used in connection with any product or service in any manner that is likely to cause confusion among customers, or in any manner that disparages EasyFit or suggests a sponsorship, affiliation or endorsement by EasyFit.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>5. Unlawful or prohibited uses</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">The App may only be used for lawful purposes in accordance with these Terms & Conditions. As a condition of your use of this App, you represent and warrant to EasyFit that you will not use the App for any purpose that is unlawful or prohibited by these Terms & Conditions. Whether on behalf of yourself or on behalf of any third party, unless you have the express prior written consent of Easyfit, YOU MAY NOT:</p>
                    <p className="fs-lg"><b>1.</b> Make any commercial use of the App or its Content, including making any collection or use of any product listings, descriptions, prices, or images;</p>
                    <p className="fs-lg"><b>2.</b> Download, copy, or transmit any Content for the benefit of any merchant other than EasyFit;</p>
                    <p className="fs-lg"><b>3.</b> Use or attempt to use any engine, software, tool, agent, data, or other device or mechanism (including browsers, spiders, robots, avatars, or intelligent agents) to navigate or search the App;</p>
                    <p className="fs-lg"><b>4.</b> Frame, mirror, or use framing techniques on any part of the App;</p>
                    <p className="fs-lg"><b>5.</b> Make any use of data extraction, scraping, mining, or other data gathering tools, or create a database by systematically downloading or storing App content, or otherwise scrape, collect, store, or use any Content, product listings, descriptions, prices, or images, except as permitted by Section V;</p>
                    <p className="fs-lg"><b>6.</b> Use any meta tags or any other hidden text utilizing EasyFit's name or trademarks;</p>
                    <p className="fs-lg"><b>7.</b> Misrepresent the identity of a user, impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with any person or entity in connection with the App, or express or imply that we endorse any statement you make;</p>
                    <p className="fs-lg"><b>8.</b> Use a buying agent to conduct transactions on the App;</p>
                    <p className="fs-lg"><b>9.</b> Conduct fraudulent activities on the App;</p>
                    <p className="fs-lg"><b>10.</b> Violate or attempt to violate the security of the App, including, without limitation:
                    <ul>
                        <li> Accessing data not intended for you or logging onto a server or an account that you are not authorized to access;</li>
                        <li> Trying to change the functionality of the App;</li>
                        <li> Attempting to probe, scan, or test the vulnerability of a system or network, or to breach security or authentication measures;</li>
                        <li> Attempting to interfere with service to any user, host, or network, including, without limitation, via means of submitting malware to the App, overloading, "flooding," "spamming," "mailbombing", or "crashing";</li>
                        <li> Forging any header or any part of the header information in any e-mail or posting; or</li>
                        <li> Forging communications on behalf of the App (impersonating the App) or to the App (impersonating another user);</li>
                    </ul>
                    </p>
                    <p className="fs-lg"><b>11.</b> Send unsolicited or unauthorized email on behalf of EasyFit, including promotions and/or advertising of products or services;</p>
                    <p className="fs-lg"><b>12.</b> Tamper with the App or use or attempt to use any device, software, routine, or data that interferes or attempts to interfere with the working or functionality of the App or any activity being conducted on the App;</p>
                    <p className="fs-lg"><b>13.</b> Use the App to defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights of others, including others' privacy rights or rights of publicity;</p>
                    <p className="fs-lg"><b>14.</b> Harvest or collect personally identifiable information about other users of the App;</p>
                    <p className="fs-lg"><b>15.</b> Restrict or inhibit any other person from using the App (including, without limitation, by hacking or defacing any portion of the App);</p>
                    <p className="fs-lg"><b>16.</b> Use the App to advertise or offer to sell or buy (other than from Snap Kitchen) any goods or services;</p>
                    <p className="fs-lg"><b>17.</b> Reproduce, duplicate, copy, sell, resell, or otherwise exploit for any commercial purposes any portion of, use of, or access to the App;</p>
                    <p className="fs-lg"><b>18.</b> Modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of the App; or</p>
                    <p className="fs-lg"><b>19.</b> Remove any copyright, trademark, or other proprietary rights notice from the App or materials originating from the App.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>6. Your account</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">You may be required to register with EasyFit in order to access certain services or areas of the App. If you are required to register with Snap Kitchen, we may not be able, or we may refuse, to provide you with the user name you request. Your user name and password are for your personal use only. If you use the App, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer and mobile device, and you agree to accept responsibility for all activities that occur under your account or password. In addition to all other rights available to EasyFit including those set forth in these Terms & Conditions, EasyFit reserves the right, in its sole discretion, to terminate your account, refuse service to you, or cancel orders.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>7. User reviews, comments, and submissions</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">You agree that any User Content that you submit: (i) will be accurate; (ii) will not violate or facilitate the violation of any law or regulation; (iii) will not violate any right of a third party, including copyright, trademark, privacy, or publicity rights; (iv) will not cause injury to any person or entity; and (v) will not contain, or provide links to, obscene, profane, or threatening language, images, or other material, malware, political campaigning, commercial solicitation, chain letters, mass mailings, any form of "spam", or any material that could be considered harmful, sexually explicit, indecent, lewd, violent, abusive, or degrading. You are solely responsible for the User Content you submit, and EasyFit assumes no liability for any User Content submitted by you. You acknowledge and agree that we reserve the right (but have no obligation) to do any or all of the following, in our sole discretion: (i) monitor User Content; (ii) alter, remove, or refuse to post or allow to be posted any User Content; and/or (iii) disclose any User Content, and the circumstances surrounding its transmission, to any third party including, without limitation, a Delivery Service.</p>
                    <p className="fs-lg">For any User Content you submit, you grant to EasyFit a non-exclusive, sub-licensable, fully paid-up, perpetual, irrevocable, royalty-free, transferable right and license to use, display, perform, transmit, copy, modify, delete, adapt, publish, translate, create derivative works from, sell and distribute such User Content and to incorporate the User Content into any form, medium, or technology, now known or hereafter developed, throughout the world, all without compensation to you. For this reason, you agree not to send us any User Content that you do not wish to license to us, including any confidential information or any original creative materials such as stories, product ideas, computer code or original artwork. In addition, you grant to EasyFit the right, without any obligation, to include the name provided along with the User Content submitted by you. We are not responsible for the use or disclosure of any personal information that you voluntarily disclose in connection with any User Content you submit. You represent and warrant that you have all rights necessary for you to grant the licenses granted in this section. You further irrevocably waive any "moral rights" or other rights with respect to attribution of authorship or integrity of materials regarding User Content that you may have under any applicable law under any legal theory.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>8. Healthcare information</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">IF THE APP INCLUDES HEALTHCARE OR NUTRITIONAL INFORMATION, THAT INFORMATION IS INTENDED FOR EDUCATIONAL PURPOSES ONLY. IT IS NOT INTENDED AS MEDICAL ADVICE FOR INDIVIDUAL CONDITIONS OR TREATMENT, IT IS NOT A SUBSTITUTE FOR A MEDICAL EXAM, AND IT DOES NOT REPLACE THE NEED FOR SERVICES PROVIDED BY MEDICAL PROFESSIONALS.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>9. Release</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">We provide information through the App for your information and enjoyment. Your use of the App is strictly voluntary. Because some of the information in the App may relate to modifications to your diet, you agree to use your good judgment and reasonable care prior to making any changes to your lifestyle. By using the App, you assume the risks associated with the activities in which you will be participating. Such risks may include, but are not limited to, the risk of physical injury or other harm. You, and you alone, are solely responsible for taking proper care to limit your risk.</p>
                    <p className="fs-lg">To the fullest extent provided by law, you knowingly and voluntarily do hereby release, acquit, waive, forever discharge, and covenant not to sue EasyFit, its employees, agents, representatives, and any related affiliate and/or subsidiary entities from and for liabilities, costs and expenses (including without limitations, any reasonable fees and expenses of its attorneys and consultants) relating to or arising out of any claims, demands or causes of action of every kind and character (including, without limitation, personal injury and property damage claims) as a result of the information and materials offered by EasyFit through the App.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>10. Limitation of liability</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">UNDER NO CIRCUMSTANCES SHALL EASYFIT OR ITS EMPLOYEES, DIRECTORS, OFFICERS, REPRESENTATIVES, OR AGENTS BE LIABLE FOR ANY DIRECT OR INDIRECT LOSSES OR DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE APP. THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES AND DAMAGES OF ANY KIND (WHETHER GENERAL, SPECIAL, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCOME OR PROFITS), WHETHER IN CONTRACT OR NOT, EVEN IF SNAP KITCHEN HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. EACH PROVISION OF THESE TERMS & CONDITIONS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS UNDER THE AGREEMENT BETWEEN YOU AND EAZYFIT. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU AND EASYFIT. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE(S). IF YOU ARE DISSATISFIED WITH THE APP, ANY CONTENT ON THE APP, OR THESE TERMS & CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE APP. YOU ACKNOWLEDGE, BY YOUR USE OF THE APP, THAT YOUR USE OF THE APP IS AT YOUR SOLE RISK. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OF LIABILITY SET FORTH ABOVE, SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU, AND YOU MAY HAVE RIGHTS ADDITIONAL TO THOSE CONTAINED HEREIN.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>11. Indemnification and defence</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">As a condition of the use of the App, you agree to defend, indemnify, and hold harmless EasyFit and its respective employees, directors, officers, agents, representatives, vendors, and suppliers from and against any liabilities, losses, investigations, inquiries, claims, suits, damages, judgments, awards, costs, fees and expenses (including, without limitation, reasonable attorneys' fees and expenses) (each, a "Claim") arising out of or otherwise relating to your violation of these Terms & Services, or your use of the App, including, without limitation, any User Content submitted by you, any use of the Contents, services, and products other than as expressly authorized in these Terms & Conditions, or your use of any information obtained from the App.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>12. Notices and electronic communications</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">You consent to receive any and all notices and other communications from EasyFit by: (i) the posting of notices on the App, (ii) transmission by email to the email address provided by you to EasyFit in connection with the creation of your account, or (iii) transmission by mail to the billing address provided by you to Easyfit in connection with the creation of your account. You agree that all agreements, notices, disclosures, and other communications that EasyFit provides to you in accordance with the prior sentence satisfy any legal requirement that such communications be in writing. Notice shall be deemed given (i) 24 hours after the notice is posted on the App or an email is sent, unless the sending party is notified that the message did not reach the recipient, or (ii) in the case of mailing, three days after the date of mailing. You agree that a printed version of these Terms & Conditions and/or any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms & Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>13. Miscellaneous</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">These Terms & Conditions, including policies and information linked from or incorporated herein (including but not limited to the Privacy Policy), constitute the entire agreement between you and Easyfit with respect to the App and supersede all prior or contemporaneous communications, agreements, and proposals with respect to the App. No provision of these Terms & Conditions shall be waived except pursuant to a writing executed by the party against whom the waiver is sought. No failure to exercise, partial exercise of, or delay in exercising any right or remedy under these Terms & Conditions shall operate as a waiver or estoppel of any right, remedy, or condition. If any provision of these Terms & Conditions is held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not be affected or impaired. You may not assign, transfer, or sublicense any of your rights or obligations under these Terms & Conditions without our express prior written consent. We will not be responsible for failure to fulfill any obligation due to causes beyond our control.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>14. Contact us</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg">If you have any concerns about Easyfit Healthy Meals or your use of the App, please contact us at <a href='mailto:easyfitng@gmail.com'>easyfitng@gmail.com</a> with a detailed description, and we will try to resolve it.</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-md-4 col-lg-4">
                    <h2 className="" style={{fontFamily: 'pt-serif, serif', fontWeight: '700', fontStyle: 'normal'}}>15. Last updated</h2>
                </div>
                <div className="col-md-8 col-xl-8">
                    <p className="fs-lg"><b>Last Updated:</b> February 28th, 2023</p>
                </div>
            </div>

        </section>

        <Footer />
    </>
  )
}
