import React from 'react'

export default function Hero() {
  return (
    <>
        {/* Hero */}
        <section className="container pt-3 mt-5 mt-md-4 mb-md-3 mb-lg-4 mb-xl-5">
            <div className="row mb-2 mb-sm-3 side-by-side">
                {/* Parallax gfx */}
                <div className="col-xl-7 col-md-6 d-flex justify-content-center justify-content-md-end overflow-hidden order-md-2 mt-n5 mt-sm-n4 mt-md-0">
                    <div className="position-relative mt-5" style={{maxWidth: '660px'}}>
                        <div className="bg-gradient-primary position-absolute start-0 bottom-0 w-100 rounded-3 opacity-15" style={{height: '95%'}} />
                        <div className="parallax zindex-2">
                            <div className="parallax-layer zindex-2 mb-n3" data-depth="0.1">
                                <img src="assets/img/layer01.png" alt="Device" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Text */}
                <div className="col-xl-5 col-md-6 d-flex flex-column order-md-1">
                    <div className="text-center text-md-start pt-4 pt-sm-5 pt-xl-0 mt-2 mt-sm-0 mt-lg-auto">
                        <h1 className="display-3 mb-4">We cross meal prep off <br /><span style={{color: "rgb(64 137 138)", boxShadow: "rgb(204 239 240) 0px -23px 0px inset"}}>Your to-do list</span></h1>
                        <p className="fs-lg mb-0 d-xl-block">Our goal is to make healthy eating a delicious, convenient and positive experience.</p>
                        <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-start pt-2 mt-4 mt-md-5">
                            <a href="/" className="btn btn-dark btn-lg px-3 py-2 me-sm-3 me-lg-4 mb-3">
                                <img src="assets/img/appstore-light.svg" className="light-mode-img" width={124} alt="App Store" />
                                <img src="assets/img/appstore-dark.svg" className="dark-mode-img" width={124} alt="App Store" />
                            </a>
                            <a href="/" className="btn btn-dark btn-lg px-3 py-2 mb-3">
                                <img src="assets/img/googleplay-light.svg" className="light-mode-img" width={139} alt="Google Play" />
                                <img src="assets/img/googleplay-dark.svg" className="dark-mode-img" width={139} alt="Google Play" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
