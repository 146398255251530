import React from 'react'
import Cta from '../components/Cta'
import Footer from '../components/Footer'
import Navbarlight from '../components/Navbarlight'

export default function About() {
    

    return (
        <>  
            <main className="page-wrapper">

                <Navbarlight />
                
                    <section className="position-relative pt-5 mb-5" style={{backgroundColor: '#0b0f19'}}>
                        <span className="d-dark-mode-none d-block position-absolute start-0 bottom-0 w-100 bg-white" style={{height: '19.875vw'}} />
                        <span className="d-dark-mode-block d-none position-absolute start-0 bottom-0 w-100 bg-light" style={{height: '19.875vw'}} />
                        <div className="dark-mode container position-relative zindex-2 pt-5">
                        
                        <div className="mt-4 mb-lg-5 mb-4 py-3">
                            <h1 className="display-4 mb-0">About Easyfit</h1>
                        </div>
                        
                        <div className="row g-md-4 g-3">
                            <div className="col-md-6">
                                <img src="assets/img/05.jpeg" alt="" className="rounded-3" />
                            </div>
                            <div className="col-md-3 col-6 d-md-block mt-5 pt-3 pt-xl-4">
                                <img src="assets/img/07.jpeg" alt="" className="rounded-3" />
                            </div>
                            <div className="col-md-3 col-6 d-md-block">
                                <img src="assets/img/06.jpeg" alt="" className="rounded-3" />
                            </div>
                        </div>
                        </div>
                    </section>


                    <section className="container position-relative zindex-3 mt-4 mb-4" style={{marginTop: '-135px'}}>
                        <div className="row pt-5 mt-n2 mt-sm-0 mt-md-2 mt-lg-4 mt-xl-5 mb-xl-5">
                            <div className="col-md-4 col-lg-5">
                                <div className="fs-sm text-uppercase mb-3">Who we are</div>
                                <h2 className="display-6">Healthy food for busy people.</h2>
                            </div>
                            <div className="col-md-7 col-xl-7 pt-1 pt-sm-2 pt-md-5 pb-5">
                                <p className="fs-lg">Arinola Odunuga, a certified fitness trainer and nutrition coach first started Easyfit in 2021 to fill a missing element in the health and fitness industry in Nigeria - delicious, convenient and affordable meal prep. Taking the hassle out of planning and preparing healthy meals so you can stay on track with your nutrition goals.</p>
                                <p className="fs-lg">We pride ourselves on using the freshest ingredients to create exciting meals based on science backed nutrition our clients can look forward to eating. Our foods contain low to medium glycemic load, no preservatives, no added sugars and zero trans fats. </p>
                                <p className="fs-lg">We  don’t just save you the trip to the market/grocery store, we make your health and fitness journey a convenient, delicious and positive experience. No shopping. No cooking. No cleaning. No calculating calories and macros.</p>
                                <p className="fs-lg">We deliver fresh, never frozen meals directly to your doorstep. Our fully-prepared-meals arrive ready to heat and eat in minutes.</p>
                            </div>
                        </div>
                    </section>

                    <Cta />
            </main>

                <Footer />
        </>
  )
}
