import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>

        <footer className="footer pt-5 pb-4 pb-lg-5 dark-mode bg-dark">
            <div className="container text-center pt-lg-3">
                <div className="navbar-brand justify-content-center text-dark mb-2 mb-lg-4">
                    <Link to='/'>
                        <img src="./logo.svg" className="me-2" width={200} alt="Logo" />
                    </Link>
                </div>
            <ul className="nav justify-content-center pt-3 pb-4 pb-lg-5">
                <li className="nav-item"><Link to='/contact' className="nav-link">Contact us</Link></li>
                <li className="nav-item"><Link to='/terms' className="nav-link">Terms of use</Link></li>
                <li className="nav-item"><Link to='/privacy' className="nav-link">Privacy policy</Link></li>
            </ul>
            <div className="d-flex flex-column flex-sm-row justify-content-center">
                <a href="/" className="btn btn-secondary btn-lg px-3 py-2 me-sm-4 mb-3">
                    <img src="assets/img/appstore-light.svg" className="dark-mode-img" width={124} alt="App Store" />
                </a>
                <a href="/" className="btn btn-secondary btn-lg px-3 py-2 mb-3">
                    <img src="assets/img/googleplay-light.svg" className="dark-mode-img" width={139} alt="Google Play" />
                </a>
            </div>
            <div className="d-flex justify-content-center pt-4 mt-lg-3">
                <a href="htts://www.instagram.com/easyfitng" className="btn btn-icon btn-secondary btn-instagram mx-2">
                    <i className="bx bxl-instagram" />
                </a>
            </div>
            <p className="nav d-block fs-sm text-center mt-lg-4 mb-0">
                <span className="opacity-80">© {(new Date().getFullYear())} EasyFit. All Rights Reserved </span>
            </p>
            </div>
        </footer>
    </>
  )
}
