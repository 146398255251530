import React from 'react'
import Footer from '../components/Footer'
import Faq from '../components/Faq'
import Cta from '../components/Cta'
import NavbarSecondary from '../components/NavbarSecondary'

export default function Faqs() {
  return (
    <>
      <main className="page-wrapper">

        <NavbarSecondary />

          <div className='container-fluid p-0 pt-3 mt-5 bg-secondary'>
            <div className="ignore-dark-mode overflow-hidden" >
              <div className="row align-items-center g-0">
                <div className="col-md-12 text-center">
                  <div className="pt-5 mt-5 mb-4 px-4 px-sm-5 pe-md-0 ps-xl-4">
                    <h1 className="display-4 mb-0">Frequently Asked<br />Questions</h1>
                    {/* <p className="fs-xs text-uppercase pt-3 pt-md-0 mb-0">Frequently Asked Questions</p> */}
                  </div>
                </div>
                {/* <div className="col-md-6 col-xl-5 d-flex justify-content-end"><img src="assets/img/faq.png" width={491} alt="Banner" /></div> */}
              </div>
            </div>
          </div>

        <Faq />

        <Cta />

      </main>

      <Footer />
    </>
  )
}
