import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbarlight() {
  return (
    <>
        {/* Navbar */}
        <header className="header navbar navbar-expand-lg fixed-top bg-dark navbar-dark">
            <div className="container px-3">
                <Link to='/' className="navbar-brand pe-3">
                    <img src="./logo.svg" width={200} alt="Logo" />
                </Link>
                <div id="navbarNav" className="offcanvas bg-dark offcanvas-end">
                    <div className="offcanvas-header border-bottom border-light">
                        <h5 className="offcanvas-title text-white">Menu</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>

                    <div className="offcanvas-body ms-lg-auto">
                        <ul className="navbar-nav mb-2 mb-lg-0 me-4">

                            <li className="nav-item me-4">
                                <Link to='/' className="nav-link">Home</Link>
                            </li>

                            <li className="nav-item me-4">
                                <Link to='/about' className="nav-link">About</Link>
                            </li>

                            <li className="nav-item me-4">
                                <Link to='/how-it-works' className="nav-link">How it works</Link>
                            </li>

                            <li className="nav-item me-4">
                                <Link to='/faq' className="nav-link">FAQ</Link>
                            </li>

                            <li className="nav-item me-4">
                                <Link to='/contact' className="nav-link">Contact us</Link>
                            </li>

                        </ul>
                    </div>

                    <div className="offcanvas-header border-top">
                        <a href="#appDownload" className="btn btn-primary w-100">
                            Get Started
                        </a>
                    </div>     

                </div>

                <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>

                <a href='#appDownload' className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex">
                    Get Started
                </a>
            </div>
        </header>
    </>
  )
}
