import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Testimonials from '../components/Testimonials'
import Hero from '../components/Hero'
import Cta from '../components/Cta'

export default function Home() {
  return (
    <div>
      <main className="page-wrapper">
        <Navbar />
        
        <Hero />

        <section className="container pb-5 mt-md-5 my-lg-5">
          <h2 className="pt-xl-2 text-center">You can have it all</h2>
          <p className='text-center pb-4 mb-2 mb-lg-3'>Health and weight loss and even better <br />you can have it delivered!</p>
          <div className="row" data-jarallax-element={-90} data-disable-parallax-down="md">

            <div className="col-12 col-lg-4 col-md-4">
              <img src="assets/img/01easyfit.jpg" style={{height: "352.667px;"}} className="rounded-3" alt="" />
              <div className="pt-3">
                <h5 className="text-center">Delicious and healthy meals</h5>
                <p className='text-center'>All or meals come with nutritional labels making it super easy to track your calories and macros.</p>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-4">
              <img src="assets/img/02easyfit.jpg" className="rounded-3" alt="" />
              <div className="pt-3">
                <h5 className="text-center">Fresh delivery everyday</h5>
                <p className='text-center'>Meal prep done easy, we take the stress of preparing meals off you so you can focus on your goals.</p>
              </div>
            </div>

            <div className="col-12 col-lg-4 col-md-4">
              <img src="assets/img/03easyfit.jpg" className="rounded-3" alt="" />
              <div className="pt-3">
                <h5 className="text-center">Ready to eat</h5>
                <p className='text-center'>No more shopping, cooking or cleaning up.</p>
              </div>
            </div>

            <div className='text-center mt-4 mb-0 pb-0'>
              <Link to='/how-it-works' className="btn btn-lg btn-dark">
                See how it works
                <i className="bx bx-right-arrow-alt fs-xl ms-2" />
              </Link>
            </div>

          </div>
            
        </section>

        <section className="bg-secondary pt-1">

            <div className="position-relative py-lg-4 py-xl-5">
            {/* Swiper tabs */}
            <div className="swiper-tabs position-absolute top-0 start-0 w-100 h-100">
                <div id="image-1" className="jarallax position-absolute top-0 start-0 w-100 h-100 swiper-tab active" data-jarallax data-speed="0.4">
                <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-35" />
                <div className="jarallax-img" style={{backgroundImage: 'url("assets/img/bg.jpeg")'}} />
                </div>
            </div>
            {/* Swiper slider */}
            <div className="container position-relative zindex-5 py-5">
                <div className="row py-2 py-md-3">
                <div className="col-xl-5 col-lg-7 col-md-9">
                    {/* Card */}
                    <div className="card bg-white shadow-sm p-3">
                        <div className="card-body">
                            <div className="swiper-wrapper">
                                {/* Item */}
                                <div className="swiper-slide" data-swiper-tab="#image-1">
                                    <h3 className="mb-2">Healthy food for busy people</h3>
                                    <p className="fs-sm text-muted border-bottom pb-3 mb-3">Your trusted source for healthy meals.</p>
                                    <p className="pb-2 pb-lg-3 mb-3">We  don’t just save you the trip to the market/grocery store, we make your health and fitness journey a convenient, delicious and positive experience. No shopping. No cooking. No cleaning. No calculating calories and macros.</p>
                                    <a href="#appDownload" className="btn btn-dark" target="_self" rel="noreferrer noopener">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        <Testimonials />

        <Cta />
        
        <Footer />
      </main>
    </div>
  )
}
